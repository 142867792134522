
import moment from 'moment'
import Vue, { PropType } from 'vue'
import api from '../../api/api'

import Breadcrumbs from '../../components/Breadcrumbs.vue'
import ClientIconsMenu from '../../components/iconLinks/ClientIconsMenu.vue'
import SpinnerLoader from '../../components/SpinnerLoader.vue'
import { Connector, Project, ProjectType } from '../../types/main'
import { CONNECTOR_TYPES } from '../../vars/general'
import BaseInputOutlined from '../../components/inputs/BaseInputOutlined.vue'
import CustomActivatorSelect from '../../components/inputs/CustomActivatorSelect.vue'
import CustomActivatorTreeSelect from '../../components/inputs/CustomActivatorTreeSelect.vue'
import _ from 'lodash'
import DidntReturnStaffGist from '../../components/chisai/GCB2/churnReport/DidntReturnStaffGist.vue'
import DidntReturnStaffTable from '../../components/chisai/GCB2/churnReport/DidntReturnStaffTable.vue'
export default Vue.extend({
    name: 'GCB2MarketingReport',
    components: {
        Breadcrumbs,
        SpinnerLoader,
        ClientIconsMenu,
        CustomActivatorSelect,
        BaseInputOutlined,
        CustomActivatorTreeSelect,
        DidntReturnStaffGist,
        DidntReturnStaffTable,
    },

    data: () => ({
        pageLoading: false,
        dashboardDataLoading: false,
        dateMenu: false,
        projectType: null as ProjectType | null,
        dates: [] as string[],
        filialList: [] as any[],
        dateFormat: 'DD.MM.YYYY',
        datePickerDateFormat: 'YYYY-MM-DD',
        filters: { productsDisplay: 0 } as any,
        filtersLists: {} as any,
        inititalFilters: {},
        dashboardData: {
            statByStaffData: [],
            statByStaffClientData: [],
        } as any,
    }),
    watch: {},
    computed: {
        project(): Project {
            return this.$store.getters.projectById(this.$router.currentRoute.params.id)
        },
        projectIsNetwork(): boolean {
            return this.projectType === 'network'
        },
        connector(): Connector | undefined {
            return this.project.connectors.find(el => el.connectorType === CONNECTOR_TYPES.GCB2)
        },
        breadcrumbs(): Array<any> {
            return [
                {
                    text: 'ВАШИ ПРОЕКТЫ',
                    to: '/projects',
                },
                {
                    text: this.project.name,
                    to: `/project/${this.project.id}`,
                },
                {
                    text: 'После каких сотрудников уходят клиенты',
                    to: '',
                    disabled: true,
                },
            ]
        },
        dateRangeText(): string {
            const formatedDates = this.sortedDates.map(date =>
                moment(date, this.datePickerDateFormat).format(this.dateFormat)
            )
            return formatedDates.join(' - ')
        },
        sortedDates(): string[] {
            return [
                ...this.dates.sort(
                    (a, b) =>
                        moment(a, this.datePickerDateFormat).unix() -
                        moment(b, this.datePickerDateFormat).unix()
                ),
            ]
        },
        dateBorders(): { to: string; from: string } {
            return {
                from: this.sortedDates[0],
                to: this.sortedDates[1],
            }
        },
        displayFilters(): any {
            return {
                staffList: Object.values(this.filters.staffList ?? {})
                    .filter((el: any) => el.selected)
                    .map((el: any) => el.id),
                serviceList: Object.values(this.filters.serviceList ?? {})
                    .filter((el: any) => el.selected)
                    .map((el: any) => el.id),
            }
        },
        queryFilters(): any {
            return _.pickBy(
                {
                    dateFrom: this.sortedDates[0],
                    dateTo: this.sortedDates[1],
                    productsDisplay: this.filters.productsDisplay,
                    staffList: this.filters.staffList,
                    filialList: this.projectIsNetwork ? this.filters.filialList : undefined,
                    serviceList: this.ingoreTreeFilter('serviceList') ? undefined : this.filters.serviceList,
                },
                (v, k) => {
                    if (_.isObject(v) && _.isEmpty(v)) {
                        return false
                    }
                    if (
                        k !== 'serviceList' &&
                        !_.isNil(v) &&
                        this.displayFilters[k] &&
                        (this.filtersLists[k].length === this.displayFilters[k].length ||
                            this.displayFilters[k].length === 0)
                    ) {
                        return false
                    }
                    return true
                }
            )
        },
    },
    methods: {
        setFiltersLists() {
            this.filtersLists = {
                serviceList: (() => {
                    const grouped = _.groupBy(this.filters.serviceList, 'service_group')
                    return Object.keys(grouped).map(key => ({
                        id: key,
                        name: key,
                        children: grouped[key].map(el => Object.assign({}, el, { name: el.option })),
                    }))
                })(),
                staffList: Object.values(this.filters.staffList ?? {}).map((el: any) => ({
                    id: el.id,
                    name: el.option,
                })),
                productsDisplay: [
                    { id: 0, name: 'Только услуги' },
                    { id: 1, name: 'Товары и услуги' },
                ],
                filialList: this.filialList,
            }
        },
        ingoreTreeFilter(filterId: string) {
            if (!this.displayFilters[filterId] || !this.filtersLists[filterId]) {
                return true
            }
            if (this.displayFilters[filterId].length === 0) {
                return true
            }
            if (
                this.displayFilters[filterId].length ===
                this.filtersLists[filterId].flatMap((el: any) => el.children).length
            ) {
                return true
            }
            return false
        },
        setDefaultDates() {
            this.dates = [
                moment()
                    .subtract(425, 'days')
                    .toISOString(),
                moment()
                    .subtract(90, 'days')
                    .toISOString(),
            ]
        },

        dropFilters() {
            this.setDefaultDates()
            this.filters = Object.assign({}, this.inititalFilters)
            this.initData()
        },
        setFilterValue(filterId: string, val: any, type: string) {
            const updatedFilterVal = {}
            const selectedHmap = val.reduce((a: any, c: any) => Object.assign(a, { [c]: true }), {})
            if (type === 'tree') {
                this.filtersLists[filterId]
                    .flatMap((el: any) => el.children)
                    .forEach((el: any) => {
                        _.setWith(
                            updatedFilterVal,
                            [el.id],
                            {
                                id: el.id,
                                option: el.name,
                                selected: Boolean(selectedHmap[el.id]),
                                service_group: el.service_group,
                            },
                            Object
                        )
                    })
            } else {
                this.filtersLists[filterId].forEach((el: any) => {
                    _.setWith(
                        updatedFilterVal,
                        [el.id],
                        {
                            id: el.id,
                            option: el.name,
                            selected: Boolean(selectedHmap[el.id]),
                            service_group: el.service_group,
                        },
                        Object
                    )
                })
            }
            this.filters = Object.assign({}, this.filters, { [filterId]: updatedFilterVal })
        },
        async initData() {
            this.dashboardDataLoading = true
            const { data, error } = await api.gcb2.getChurnReportData({
                projectId: this.project.id,
                filters: this.queryFilters,
            })
            if (error) {
                this.$store.dispatch('callNotify', 'Ошибка')
            }
            this.dashboardData = data.reportData
            this.filters = Object.assign({}, this.filters, data.filters)
            this.setFiltersLists()
            this.dashboardDataLoading = false
        },
        async initFilters() {
            this.filters.filialList = this.filialList.map(el => el.id)
            this.inititalFilters = Object.assign({}, this.filters)
        },
    },
    async mounted() {
        this.pageLoading = true
        const responses = await Promise.all([
            api.project.getType({ projectId: this.project.id }).then(res => res.data?.type!),
            api.project.getNetworkProjects({ projectId: this.project.id }).then(res => res.data!),
        ])
        this.projectType = responses[0]
        this.filialList = responses[1]
        this.initFilters()

        this.setDefaultDates()
        await this.initData()
        this.pageLoading = false
    },
})
