<template>
    <div class="mb-16">
        <DidntReturnStaffTableSubHeader :subHeaderData="subHeaderData"></DidntReturnStaffTableSubHeader>
        <v-data-table
            :sort-by.sync="sortColumn"
            :sort-desc.sync="sortDesc"
            :headers="headers"
            :items="chartData"
            @current-items="setSubHeaderData"
        >
            <template v-slot:[`item.client_name_with_num`]="{ item }">
                <v-menu offset-x v-if="item.href_info">
                    <template v-slot:activator="{ on, attrs }">
                        <a v-bind="attrs" v-on="on">{{ item.client_name_with_num }}</a>
                    </template>
                    <div>
                        <ClientIconsMenu :hrefInfo="item.href_info" :connector="connector"></ClientIconsMenu>
                    </div>
                </v-menu>
                <span v-else> {{ item.client_name_with_num }}</span>
            </template>
            <template v-slot:[`item.visit_date`]="{ item }">
                <span>{{ item.visit_date ? moment(item.visit_date).format(dateFormat) : '' }}</span>
            </template>
            <template v-slot:[`item.first_visit`]="{ item }">
                <span>{{ item.first_visit ? moment(item.first_visit).format(dateFormat) : '' }}</span>
            </template>
            ></v-data-table
        >
    </div>
</template>

<script>
import ClientIconsMenu from '@root/src/components/iconLinks/ClientIconsMenu.vue'
import { openInNewTab } from '../../../../utils'
import { getYcClientRef, toPercent } from '../helpers'
import DidntReturnStaffTableSubHeader from './subHeaders/DidntReturnStaffTableSubHeader.vue'
import { CONNECTOR_TYPES } from '@root/src/vars/general'

export default {
    name: 'DidntReturnStaffTable',
    components: { DidntReturnStaffTableSubHeader, ClientIconsMenu },
    props: {
        chartData: {
            type: Array,
            default: () => [],
        },
        dateBorders: {
            type: Object,
            default: () => {},
        },
    },
    data: () => ({
        sortColumn: 'visit_date',
        dateFormat: 'DD MMMM YYYY г.',
        sortDesc: true,
        headers: [
            {
                text: 'Мастер',
                align: 'start',
                value: 'staff_name',
            },
            {
                text: 'Клиент',
                align: 'start',
                value: 'client_name_with_num',
            },
            {
                text: 'Услуга',
                align: 'start',
                value: 'service_name',
            },
            {
                text: 'Дата последнего посещения',
                align: 'start',
                value: 'visit_date',
            },
            {
                text: 'Всего посещений у клиента',
                align: 'start',
                value: 'count_attendance',
            },
            {
                text: 'Дата первого посещения',
                align: 'start',
                value: 'first_visit',
            },
            {
                text: 'Филиал первого посещения',
                align: 'start',
                value: 'first_visit_project',
            },
        ],
        fullBarWidth: 200,
        subHeaderData: {},
    }),
    computed: {
        project() {
            return this.$store.getters.projectById(this.$router.currentRoute.params.id)
        },
        connector() {
            return this.project.connectors.find(el => el.connectorType === CONNECTOR_TYPES.GCB2)
        },
    },
    methods: {
        openInNewTab,
        getYcClientRef,
        setSubHeaderData(curItems) {
            if (curItems.length > 0) {
                this.subHeaderData = {
                    startDate: this.dateBorders.from,
                    endDate: this.dateBorders.to,
                    clientName: curItems[0].client_name_with_num,
                    staffName: curItems[0].staff_name,
                    attCount: curItems[0].count_attendance,
                    lastDate: curItems[0].visit_date,
                    serviceTittle: curItems[0].service_tittle,
                }
            }
        },
    },

    created() {},
}
</script>
<style lang="sass" scoped>
.bar-wrapper
    display: flex
    flex-direction: row
    align-items: center
.bar

    height: 20px
    background-color: #378EFF
</style>
